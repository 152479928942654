import { useEffect, useState } from 'react'

import classNames from 'classnames'
import Header from 'components/Organisms/Header'
import { FaArrowUp } from 'react-icons/fa'

const HomeLayout = ({ children }: any) => {
  const [scrolled, setScrolled] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  const headerClassName = classNames(
    'bg-white',
    'fixed',
    'w-full',
    'top-0',
    'left-0',
    'mx-auto',
    'z-50',
    'transition-all',
    'duration-500',
    { 'shadow-md': scrolled },
  )
  const upButtonClassName = classNames(
    'fixed',
    'bottom-6',
    'right-6',
    'sm:bottom-16',
    'sm:right-16',
    'z-50',
    'transition-all',
    'duration-300',
    'ease-in-out',
    'transform',
    {
      'scale-0': !scrolled,
      'scale-100': scrolled,
    },
  )
  const handleOnClick = (sectionId: any) => {
    if (sectionId) {
      const sectionElement = document.getElementById(sectionId)
      if (sectionElement) {
        const headerOffset = 100
        const elementPosition = sectionElement.getBoundingClientRect().top
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }
  }
  return (
    <div className="w-screen h-full">
      <div className={headerClassName}>
        <Header logo="logo.png" onClick={handleOnClick} />
      </div>
      <div className="">{children}</div>
      <div className={upButtonClassName}>
        <button
          className="rounded-full p-3 bg-blue-800 text-2xl shadow-md transition-all duration-500"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <FaArrowUp className="scrollTop text-white" />
        </button>
      </div>
    </div>
  )
}

export default HomeLayout
