import axios from 'axios'

export const PublicPrivateInterceptor = () => {
  axios.interceptors.request.use(function (config) {
    if (
      config.url?.includes('login') ||
      config.headers?.Authorization ||
      config.url?.includes('passwordrecovery')
    ) {
      return config
    }
    const token = process.env.REACT_APP_TOKEN!
    config.headers.Authorization = token

    return config
  })

  axios.interceptors.response.use(
    response => {
      return response
    },
    function (error) {
      if (error.request?.status === 401) {
        window.location.href = '/'
      }
      return Promise.reject(error)
    },
  )
}
